<template>
  <!-- 通知中心 -->
  <div id="app">
    <div class="cart-con">
      <div class="cart-ban">
        <img src="@/assets/img/notice-ban.jpg" />
      </div>
      <div class="cson-container notice-con">
        <div class="title">
          通知中心

          <el-select
            style="float: right; transform: translate(-20px, -10px)"
            v-model="noticeType"
            @change="(val) => changeNoticeList(val, 1)"
          >
            <el-option value="" label="全部"></el-option>
            <el-option value="0" label="站务通知"></el-option>
            <el-option value="1" label="评论与回复"></el-option>
            <el-option value="2" label="赞同与喜欢"></el-option>
          </el-select>
        </div>
        <div class="noticeTips">
          系统自动清理三个月以前的已读站务通知

          <span
            style="cursor: pointer; float: right; color: #666"
            @click="readAllNotice"
            >全部标记为已读</span
          >
        </div>
        <div class="notice-list">
          <div v-for="(item, index) in noticeList" :key="index" class="slist">
            <h3
              v-if="item.type"
              :class="item.type.name == '系统通知' ? '' : 'bg1'"
            >
              {{ item.type.name }}
            </h3>
            <h2
              style="display: inline-block; margin-left: 12px"
              v-if="item.subject"
              v-html="item.subject"
            ></h2>
            <span
              v-if="!item.readStatus"
              style="margin-left: 5px; padding: 0 12px"
              >未读</span
            >
            <p :class="item.isopen ? 'open' : ''" v-html="item.content">
              {{ item.content }}
            </p>
            <div class="date">
              <img src="@/assets/center/date-icon.png" />
              {{ item.createTime }}
            </div>
          </div>
          <div v-if="total == 0" class="noNotices">暂无通知</div>
          <el-pagination
            v-if="total > 0"
            :current-page="currentPage"
            :page-size="10"
            :total="total"
            class="notice-paginationn"
            @current-change="(page) => changeNoticeList(noticeType, page)"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryMessageList, readAllNotices } from "../../service/index";
import { getIn } from "../../utils/index";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      noticeList: [],
      currentPage: 1,
      total: 0,
      loading: true,
      noticeType: "",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.changeNoticeList("", 1);
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedNotice = val
        ? this.noticelist.map((item) => {
            return item.id;
          })
        : [];
      this.isIndeterminate = false;
    },
    readAllNotice() {
      const data =
        this.noticeType != ""
          ? {
              type: this.noticeType,
            }
          : {};
      readAllNotices(data).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.changeNoticeList(this.noticeType, 1);
          this.$bus.$emit("updateNotice", "update");
        }
      });
    },
    handleChangeTab(tab) {
      switch (tab.name) {
        case "sysNotice":
          this.getSysNoticeList();
          break;
        case "commentNotice":
          this.getCommentNoticeList();
          break;
        case "likeNotice":
          this.getLikeNoticeList();
          break;
      }
    },
    async changeNoticeList(value, page) {
      const res = await queryMessageList({
        current: page ? page : 1,
        size: 10,
        type: value,
      });
      this.noticeList = res.result.page.records;
      this.total = res.result.page.total;
    },
  },
};
</script>

<style scoped>
.noNotices {
  text-align: center;
  font-size: 24px;
  margin: 20px 0;
}
.noticeTips {
  background: #f3f5f7;
  padding: 12px 40px;

  color: #999;
  font-size: 14px;
}
.date {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
